import React, { useState, useEffect } from 'react';
import { Grid, Typography, Box, useMediaQuery } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { KeyboardArrowUp } from '@mui/icons-material';
import { useNavigate } from "react-router-dom";
import darkTheme from '../../theme';
import CustomAppBar from '../../components/layout/AppBar';
import ResumeDialog from '../../components/dialogs/ResumeDialog';
import CustomBottomBar from '../../components/layout/BottomBar';
import ExcelFile from '../../assets/img/ExcelFile.png';
import MasterMind from '../../assets/img/MasterMindPic.png';
import Otgw from '../../assets/img/Otgw.jpg';
import BlogPost from '../../components/BlogPost';
import '../home/styles.css';

const IndexBlog = () => {
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [showButton, setShowButton] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
            setShowButton(scrollTop > 0);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleNavigation = (path) => {
      navigate(path); // Navigate to the specified path
  };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    const handleSelectPost = (option) => {
        switch (option) {
            case "Control de Herramienta (VBA)":
                navigate('/blog/excel_project');
                break;
            case "MasterMind Game (Python)":
                navigate('/blog/mm_project');
                break;
            case "Over the Garden Wall":
                navigate('/blog/over_the_garden_wall');
                break;
        }
    }

    const smallScreenBreakpoint = '(max-width: 768px)'; // Adjust the breakpoint as needed
    const isSmallScreen = useMediaQuery(smallScreenBreakpoint);

    return (
        <ThemeProvider theme={darkTheme}>
            <Grid container direction="column">
                <CustomAppBar title={'Home'} />
                <div className='Body'>
                    <Grid container direction='column'>
                        {/* MENU */}
                        <Grid container direction='row' alignItems={'start'}>
                            {!isSmallScreen && (
                                <Grid item container direction="column" spacing={8} xs={2} sm={1} md={1}>
                                    <Grid item xs={2}>
                                        <Typography variant="h6" className="list-item" onClick={() => handleNavigation('/')}>Home</Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Typography variant="h6" className="list-item" onClick={() => setOpen(true)}>Resume</Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Typography variant="h6" className="list-item" onClick={() => handleNavigation('/blog')}>Blog</Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Typography variant="h6" className="list-item" onClick={() => handleNavigation('/contact')}>Contact</Typography>
                                    </Grid>
                                </Grid>
                            )}
                            <Grid item xs={12} md={4} />
                            <Grid item container direction="column" xs={12} md={7}>
                                <Typography fontWeight={'bold'} variant='h5' sx={{ color: 'white', textAlign: 'center', mb: 3 }}>
                                    Posts
                                </Typography>
                                <Typography fontWeight={'regular'} variant='body1' sx={{ color: 'white' }}>
                                    So i was thinking what could i possibly add to this website besides the regular home page and contact page, and 
                                    i came up with the idea of showing you some of my personal projects that i have worked on, not the actual code thought,
                                    just some snippets of it.<br/><br/>
                                </Typography>
                                <Box height={2} />
                                <Typography fontWeight={'regular'} variant='body1' sx={{ color: 'white' }}>
                                    Oh yeah, also i talk about other things besides my projects so this pages doesn't feel empty hahaha
                                </Typography>
                                <Box height={150} />

                            </Grid>
                        </Grid>
                        
                        <Grid container spacing={3} justifyContent="space-between" alignItems="center">
                            <Grid item xs={12} sm={6} md={4}>
                                <BlogPost text1="Control de Herramienta (VBA)" text2="Un archivo de excel para empresas constructoras" 
                                    text3="Erick Lopez" text4="" imageUrl={ExcelFile} sizew="100%" sizeh="300px"
                                    handleSelectPost={handleSelectPost} />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <BlogPost text1="MasterMind Game (Python)" text2="The classic not so classic game coded on python" 
                                    text3="Erick Lopez" text4="" imageUrl={MasterMind} sizew="100%" sizeh="300px"
                                    handleSelectPost={handleSelectPost} />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <BlogPost text1="Over the Garden Wall" text2="An analysis of this masterpiece" 
                                    text3="Erick Lopez" text4="" imageUrl={Otgw} sizew="100%" sizeh="300px"
                                    handleSelectPost={handleSelectPost} />
                            </Grid>
                        </Grid>
                        <Box height={8} />
                    </Grid>
                </div>
                <CustomBottomBar scrollToTop={scrollToTop} setOpen={setOpen} page={'Blog'} />
            </Grid>
            <ResumeDialog open={open} setOpen={setOpen} />
            {showButton && (
                <button className="scroll-to-top-btn" onClick={scrollToTop}>
                    <KeyboardArrowUp />
                </button>
            )}
        </ThemeProvider>
    );
}

export default IndexBlog;
