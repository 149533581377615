const PatternIcon = () => {

    return (
        <svg width="348" height="550" viewBox="0 0 348 550" fill="none" xmlns="http://www.w3.org/2000/svg">
<g id="Pattern">
<g id="Center">
<rect id="Rectangle 3" x="37.9392" y="274.413" width="192.917" height="192.917" transform="rotate(-45 37.9392 274.413)" fill="black" stroke="white"/>
<rect id="Rectangle 4" x="58.1473" y="274.093" width="164.111" height="164.111" transform="rotate(-45 58.1473 274.093)" stroke="white"/>
</g>
<g id="TopMedium">
<rect id="Rectangle 3_2" x="183.765" y="128.562" width="115.35" height="115.35" transform="rotate(-45 183.765 128.562)" fill="black" stroke="white"/>
<rect id="Rectangle 4_2" x="199.366" y="128.022" width="94.5905" height="94.5905" transform="rotate(-45 199.366 128.022)" stroke="white"/>
</g>
<g id="TopSmall">
<rect id="Rectangle 3_3" x="176.784" y="41.136" width="57.1751" height="57.1751" transform="rotate(-45 176.784 41.136)" fill="#040000" stroke="white"/>
<rect id="Rectangle 4_3" x="187.5" y="41.3006" width="42.4502" height="42.4502" transform="rotate(-45 187.5 41.3006)" stroke="white"/>
</g>
<g id="BottomSmall">
<rect id="Rectangle 3_4" x="88.3576" y="508.864" width="57.1751" height="57.1751" transform="rotate(-45 88.3576 508.864)" fill="black" stroke="white"/>
<rect id="Rectangle 4_4" x="99.074" y="509.029" width="42.4502" height="42.4502" transform="rotate(-45 99.074 509.029)" stroke="white"/>
</g>
<g id="BottomMedium">
<rect id="Rectangle 3_5" x="0.707107" y="420.989" width="115.35" height="115.35" transform="rotate(-45 0.707107 420.989)" fill="#010000" stroke="white"/>
<rect id="Rectangle 4_5" x="15.439" y="421.318" width="94.5905" height="94.5905" transform="rotate(-45 15.439 421.318)" stroke="white"/>
</g>
</g>
</svg>

    );
}
export default PatternIcon