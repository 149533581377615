const Eng = () => {
    return (
        <svg width="102" height="127" viewBox="0 0 102 127" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Eng">
        <g id="Group 10">
        <path id="Vector 4" d="M13 114.5V12C13 6.47715 17.4772 2 23 2H68.0518C70.8835 2 73.5825 3.20055 75.4787 5.30363L97.4269 29.6462C99.0833 31.4832 100 33.869 100 36.3426V114.5C100 120.023 95.5228 124.5 90 124.5H23C17.4772 124.5 13 120.023 13 114.5Z" stroke="white" stroke-width="4"/>
        <path id="Vector 5" d="M71 2V27.5C71 30.2614 73.2386 32.5 76 32.5H99.5" stroke="white" stroke-width="4"/>
        <path id="Vector 6" d="M58 51.5H7.5C4.73858 51.5 2.5 53.7386 2.5 56.5V77C2.5 79.7614 4.73858 82 7.5 82H58C60.7614 82 63 79.7614 63 77V56.5C63 53.7386 60.7614 51.5 58 51.5Z" fill="white" stroke="white" stroke-width="4"/>
        </g>
        <path id="Vector 7" d="M30 72H8C5.23858 72 3 69.7614 3 67V57C3 54.2386 5.23858 52 8 52H30C32.7614 52 35 54.2386 35 57V67C35 69.7614 32.7614 72 30 72Z" fill="#001376" stroke="white" stroke-width="4"/>
        <path id="Vector 10" d="M60 58H35C33.8954 58 33 57.1046 33 56C33 54.8954 33.8954 54 35 54H60C61.1046 54 62 54.8954 62 56C62 57.1046 61.1046 58 60 58Z" fill="#A40000"/>
        <path id="Vector 11" d="M60 66H35C33.8954 66 33 65.1046 33 64C33 62.8954 33.8954 62 35 62H60C61.1046 62 62 62.8954 62 64C62 65.1046 61.1046 66 60 66Z" fill="#A40000"/>
        <path id="Vector 12" d="M60 74H7C5.89543 74 5 73.1046 5 72C5 70.8954 5.89543 70 7 70H60C61.1046 70 62 70.8954 62 72C62 73.1046 61.1046 74 60 74Z" fill="#A40000"/>
        <path id="Vector 13" d="M60 81H7C5.89543 81 5 80.1046 5 79C5 77.8954 5.89543 77 7 77H60C61.1046 77 62 77.8954 62 79C62 80.1046 61.1046 81 60 81Z" fill="#A40000"/>
        </g>
        </svg>
    )
}

export default Eng;