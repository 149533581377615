const Esp = () => {
    return (
        <svg width="102" height="127" viewBox="0 0 102 127" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Esp">
        <g id="Group 11">
        <path id="Vector 4" d="M12.5 114.5V12C12.5 6.47715 16.9772 2 22.5 2H67.5518C70.3835 2 73.0825 3.20055 74.9787 5.30363L96.9269 29.6462C98.5833 31.4832 99.5 33.869 99.5 36.3426V114.5C99.5 120.023 95.0228 124.5 89.5 124.5H22.5C16.9772 124.5 12.5 120.023 12.5 114.5Z" stroke="white" stroke-width="4"/>
        <path id="Vector 5" d="M70.5 2V27.5C70.5 30.2614 72.7386 32.5 75.5 32.5H99" stroke="white" stroke-width="4"/>
        <path id="Vector 6" d="M57.5 51.5H7C4.23858 51.5 2 53.7386 2 56.5V77C2 79.7614 4.23858 82 7 82H57.5C60.2614 82 62.5 79.7614 62.5 77V56.5C62.5 53.7386 60.2614 51.5 57.5 51.5Z" fill="white" stroke="white" stroke-width="4"/>
        </g>
        <path id="Vector 14" d="M20 53H9C6.23858 53 4 55.2786 4 58.04V72.04V76C4 78.7614 6.23858 81 9 81H20C22.7614 81 25 78.7614 25 76V58C25 55.2386 22.7614 53 20 53Z" fill="#093D00"/>
        <path id="Vector 16" d="M34 64H31.0311C28.8906 64 27.2345 65.876 27.5 68C27.2345 70.124 28.8906 72 31.0311 72H34C36.2091 72 38 70.2091 38 68C38 65.7909 36.2091 64 34 64Z" fill="black"/>
        <path id="Vector 15" d="M56 52H46C43.2386 52 41 54.2386 41 57V57.22V71.72V76C41 78.7614 43.2386 81 46 81H56C58.7614 81 61 78.7614 61 76V57C61 54.2386 58.7614 52 56 52Z" fill="#A40000"/>
        </g>
        </svg>
    ) 
}

export default Esp;